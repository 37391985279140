import React from 'react';
import './Header.css'
import logoqq1221 from './logo_2.png'
import { Link } from 'react-router-dom';

function Header() { 
  return (
    <div className='header-menu'>
      <Link to='/daily-bonus'>
        <img src={logoqq1221} alt='logo' className='header-logo'/>
      </Link>
      <ul className='menu-items'>
        <li>
          <Link className='menu-button' to='/daily-bonus'>Daily Bonus</Link>
        </li>
        <li>
          <Link className='menu-button' to='./monthly-bonus'>Special Bonus</Link>
        </li>
      </ul>
    </div>
  )
}

export default Header